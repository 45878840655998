body, div#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App,#root {
  background-color: black;
  min-width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}

.home{
  background: radial-gradient(black 20%, transparent 70%);
}

a{
  color: rgb(42, 117, 223) !important;
}