.App {
  background-color: black;
  min-width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}

.bg1,
.bg2,
canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.bg1,
.bg2 {
  width: 100%;
  height: 100vh;
  transition: opacity 1s;
}

.bg1 {
  z-index: 0;
  background-image: radial-gradient(black 30%, #020327, #02002f);
  animation: fadeInOut 2s infinite;
}

.bg2 {
  z-index: 1;
  background-image: radial-gradient(rgb(7, 4, 29), #0d0115, #01150f);
  animation-delay: 1s;
  animation: fadeInOut 2s infinite;
}

canvas {
  z-index: 3;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
