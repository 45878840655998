.App {
  position: relative;
  overflow: hidden !important;
  max-width: 100vw !important;
}

.Hawaii{
  background: radial-gradient(rgb(10, 10, 10), rgb(18, 1, 22)) !important;
}

canvas{
  position: absolute;
  top: 0;
  left: 0;
}

/* #b {
  transition: transform 10s ease-in-out;
  animation: baunc 10s infinite;
}

@keyframes baunc {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.01);
  }
} */
